const MENU = {
  branch_management: [
    {
      name: "branches",
      link: "/dashboard/branches",
    },
    {
      name: "request_for_new_branch",
      link: "/dashboard/request-branches",
    },
  ],
  manager_invitations: "/dashboard/manager-invitations",
  managers: "/dashboard/managers",
  barmen_list: "/dashboard/barmen",
  goods_type: "/dashboard/goods-type",
  goods: "/dashboard/goods",
  inventories: "/dashboard/inventories",
  settings: [
    {
      name: "integration",
      link: "/dashboard/settings/integration",
    },
    {
      name: "measurement_unit",
      link: "/dashboard/settings/measurement-unit",
    },
  ],
  integration: [
    {
      name: "iiko_products",
      link: "/dashboard/iiko-integration-products",
    },
    {
      name: "semi_manufactures",
      link: "/dashboard/iiko-integration-semi-manufactures",
    },
  ],
};

export default MENU;

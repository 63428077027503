import React, { Suspense, useState, useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { LeftSidebar } from "./layout-blueprints";
import useStore from "./context";
import * as Api from "./service";
import ScrollToTop from "components/scroll-top";
import Privacy from "components/privacy";
import Terms from "./components/terms";
import { SuspenseLoading } from "components";
import {
  PasswordChangeForm,
  Invitation,
  EmailVerify,
  LandingPage,
  SignIn,
  SignUp,
  ForgetPassword,
  ResetPassword,
  Continue,
  Settings,
  IikoLogin,
  Support,
} from "./pages";

import {
  Branches,
  BranchesForm,
  RequestBranchesForm,
  RequestBranches,
  ManagerInvitation,
  ManagerInvitationForm,
  Managers,
  ManagersForm,
  Barmens,
  BarmensForm,
  GoodList,
  GoodForm,
  GoodTypesList,
  GoodTypesForm,
  InventoriesList,
  InventoriesView,
  EditProfile,
  IIkoResolve,
  InventoryPrepare,
  IikoIntegrationGoods,
  IikoIntegrationSemiManufactures,
} from "./pages/dashboard";

import { InventoriesViewItems } from "./pages/dashboard/inventories";
import { TOKEN } from "Constants";

const Routes = () => {
  const [show, setShow] = useState(false);
  const { user, setUser } = useStore();
  const { pathname } = useLocation();
  const history = useHistory();
  const localToken = localStorage.getItem(TOKEN);
  const sessionToken = sessionStorage.getItem(TOKEN);

  const logOut = async () => {
    await Api.main.logOff();
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    setUser(null);
  };

  useEffect(() => {
    if (pathname.includes("dashboard")) {
      document.documentElement.classList.remove("body-styles");
    } else {
      document.documentElement.classList.add("body-styles");
    }
  }, [pathname]);

  useEffect(() => {
    const local = localStorage.getItem(TOKEN);
    const session = sessionStorage.getItem(TOKEN);
    if (local || session) {
      Api.sign
        .getUser()
        .then((res) => {
          setUser(res);
          setShow(true);
        })
        .catch((e) => {
          localStorage.removeItem(TOKEN);
          sessionStorage.removeItem(TOKEN);
          setShow(true);
        });
    } else {
      setShow(true);
    }
  }, [setUser]);

  useEffect(() => {
    if (user && pathname === "/sign_in") {
      history.push("/");
    }
    if (
      user &&
      (pathname.includes("new-password") ||
        pathname.includes("register/continue"))
    ) {
      logOut();
    }
  }, [history, pathname, user]);

  if (!show) {
    return <SuspenseLoading />;
  }

  return (
    <Suspense fallback={<SuspenseLoading />}>
      <ScrollToTop />
      {pathname === "/support" ? (
        <Route exact path="/support" component={Support} />
      ) : (
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/home" component={LandingPage} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/invitation/:token" component={Invitation} />
          {!user && !localToken && !sessionToken && (
            <>
              <Route path="/sign_in" component={SignIn} />
              <Route path="/sign_up" component={SignUp} />
              <Route path="/forget_password" component={ForgetPassword} />
              <Route path="/reset_password" component={ResetPassword} />
              <Route
                exact
                path="/verify/email/:token"
                component={EmailVerify}
              />
              <Route
                exact
                path="/new-password/:token/:email"
                component={ResetPassword}
              />
              <Route
                exact
                path="/new-password/:token"
                component={ResetPassword}
              />
              <Route path="/register/continue/:token" component={Continue} />
            </>
          )}

          {user && (
            <LeftSidebar>
              <Route
                exact
                path="/dashboard/password-change"
                component={PasswordChangeForm}
              />
              <Route
                exact
                path="/dashboard/edit-profile"
                component={EditProfile}
              />
              <Route
                exact
                path="/dashboard/branches/create/:id?"
                component={BranchesForm}
              />
              <Route exact path="/dashboard/branches" component={Branches} />
              <Route
                exact
                path="/dashboard/request-branches/create"
                component={RequestBranchesForm}
              />
              <Route
                exact
                path="/dashboard/request-branches"
                component={RequestBranches}
              />
              <Route
                exact
                path="/dashboard/manager-invitations/create/:id?"
                component={ManagerInvitationForm}
              />
              <Route
                exact
                path="/dashboard/manager-invitations"
                component={ManagerInvitation}
              />
              <Route
                exact
                path="/dashboard/managers/edit/:id?"
                component={ManagersForm}
              />
              <Route exact path="/dashboard/managers" component={Managers} />
              <Route
                exact
                path="/dashboard/barmen/create/:id?"
                component={BarmensForm}
              />
              <Route exact path="/dashboard/barmen" component={Barmens} />
              <Route
                exact
                path="/dashboard/goods-type/create/:id?"
                component={GoodTypesForm}
              />
              <Route
                exact
                path="/dashboard/goods-type"
                component={GoodTypesList}
              />
              <Route
                exact
                path="/dashboard/goods/create/:id?"
                component={GoodForm}
              />
              <Route
                exact
                path="/dashboard/iiko-integration-products"
                component={IikoIntegrationGoods}
              />
              <Route
                exact
                path="/dashboard/iiko-integration-semi-manufactures"
                component={IikoIntegrationSemiManufactures}
              />
              <Route
                exact
                path="/dashboard/iiko-unresolved"
                component={IIkoResolve}
              />
              <Route
                exact
                path="/dashboard/settings/integration"
                component={IikoLogin}
              />
              <Route
                exact
                path="/dashboard/inventories-prepare/:id"
                component={InventoryPrepare}
              />
              <Route exact path="/dashboard/goods" component={GoodList} />
              <Route
                exact
                path="/dashboard/settings/measurement-unit"
                component={Settings}
              />
              <Route
                exact
                path="/dashboard/inventories"
                component={InventoriesList}
              />
              <Route
                exact
                path="/dashboard/inventories/:id/:did"
                component={InventoriesView}
              />
              <Route
                exact
                path="/dashboard/inventories/:id/:good_id/:did"
                component={InventoriesViewItems}
              />
            </LeftSidebar>
          )}
        </Switch>
      )}
    </Suspense>
  );
};

export default Routes;
